import React from 'react';

import axios from 'axios';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Button,
  Chip,
  ClickAwayListener,
  Fade,
  Grid,
  Paper,
  Popper,
  Avatar,
  List,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  Typography,
  ListItemButton
} from '@mui/material';

// third party
import PerfectScrollbar from 'react-perfect-scrollbar';

// assets
import QueryBuilderTwoToneIcon from '@mui/icons-material/QueryBuilderTwoTone';
import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone';

import { useSelector } from 'react-redux';

import Badge from '@mui/material/Badge'; 

function formatDate(date) {
  const options = { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return date.toLocaleDateString('pt-BR', options);
}

const NotificationSection = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [notifications, setNotifications] = React.useState([]);

  const user = useSelector(state => state.user);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_BACKEND}/${user.storeInfo.slug}/admin/general_settings/notifications`, {
      headers: {
        'Authorization': process.env.REACT_APP_API_AUTHORIZATION,
      }
    }).then(response => {
        setNotifications(response.data);
      })
      .catch(error => {
        console.error('Erro na requisição:', error);
      });
  }, []);

  return (
    <>
      <Button
        sx={{
          minWidth: { sm: 50, xs: 35 }
        }}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        aria-label="Notification"
        onClick={handleToggle}
        color="inherit"
      >
      <Badge badgeContent={notifications.length} color="secondary">
        <NotificationsNoneTwoToneIcon sx={{ fontSize: '1.5rem' }} />
      </Badge>
      </Button>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 10]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              altAxis: true // false by default
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 350,
                    minWidth: 250,
                    backgroundColor: theme.palette.background.paper,
                    pb: 0,
                    borderRadius: '10px'
                  }}
                > 

                {notifications.length === 0 ? (<><ListSubheader disableSticky>
                    Nenhum agendamento recente
                    </ListSubheader></>) : (<PerfectScrollbar style={{ height: 320, overflowX: 'hidden' }}>
                    <ListSubheader disableSticky>
                      <Chip size="small" color="primary" label="Novo" />
                    </ListSubheader>

                    {
                      notifications.map((notification, index) => (
                        <ListItemButton alignItemsFlexStart sx={{ pt: 0 }}>
                      <ListItemAvatar>
                        <Avatar alt="avatar" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<Typography variant="subtitle1">{notification.name}</Typography>}
                        secondary={<Typography variant="subtitle2">{notification.message}</Typography>}
                      />
                      <ListItemSecondaryAction sx={{ top: 22 }}>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <QueryBuilderTwoToneIcon
                              sx={{
                                fontSize: '0.75rem',
                                mr: 0.5,
                                color: theme.palette.grey[400]
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography variant="caption" display="block" gutterBottom sx={{ color: theme.palette.grey[400] }}>
                              {formatDate(new Date(notification.created_at))}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                      ))
                    }

                  </PerfectScrollbar>)}
                </List>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default NotificationSection;
